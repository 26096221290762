<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 16:52:31
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
#project {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .select-status {
    width: 150px;
    margin-right: 14px;
  }
  .model-action {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(68, 125, 245, 1);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>
<template>
  <div class="project" id="project">
    <div class="tab-top">
      <table-title />
      <table-action alias="project"  @search="onSearch" @success="onSuccess">
        <a-select placeholder="请选择状态" class="select-status" slot="custom" v-model="project_status" @change="selectStatus">
          <a-select-option :key="0">全部</a-select-option>
          <a-select-option :key="1">未发布</a-select-option>
          <a-select-option :key="2">暂停</a-select-option>
          <a-select-option :key="3">正在调查</a-select-option>
          <a-select-option :key="4">已完成</a-select-option>
          <!-- <a-select-option :key="5">奖励完成的</a-select-option> -->
        </a-select>
      </table-action>
    </div>
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :pagination="{
        current, 
        total,
        pageSize: size, 
      }"
      @change="tabChange"
      style="margin-top: 13px"
    >
      <div slot-scope="record" slot="action">
        <span class="model-action" v-show="record.project_status==1" @click="onEdit(record.project_id)">修改</span>
        <a-divider type="vertical" v-show="record.project_status==1"/>
        <span class="model-action" v-show="record.project_status==1 || record.project_status==2" @click="onPush(record.project_id)">发布</span>
        <span class="model-action" v-show="record.project_status==3" @click="onPause(record.project_id)">暂停</span>
        <a-divider type="vertical" v-show="record.project_status==3 || record.project_status==2"/>
        <span class="model-action" v-show="record.project_status==3 || record.project_status==2" @click="onFinish(record.project_id)">完结</span>
        <a-divider type="vertical" v-show="record.project_status!=4"/>
        <span class="model-action" v-show="record.project_status!=4" @click="setUrl(record.project_id, record.url_type, record.project_title)">配置链接</span>
        <a-divider type="vertical" v-show="record.project_status!=4"/>
        <span class="model-action" v-show="record.project_status>2" @click="onEdit(record.project_id)">详情</span>
        <a-divider type="vertical" v-show="record.project_status>2"/>
        <project-statistic :projectId="record.project_id"></project-statistic>
      </div>
    </a-table>
  </div>
</template>

<script>
import { fetchProjectlist, updateProjectStatus } from "@/api/project";
const columns = [
  {
    title: "项目ID",
    dataIndex: "project_display_id",
  },
  {
    title: "项目名称",
    dataIndex: "project_title",
  },
  {
    title: "奖励方式",
    dataIndex: "pay_type",
  },
  {
    title: "项目类型",
    dataIndex: "project_type_text",
  },
  {
    title: "项目经理",
    dataIndex: "manager_name",
  },
  {
    title: "项目单价",
    dataIndex: "coin",
  },
  {
    title: "项目状态",
    dataIndex: "project_status_text",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  }
];

export default {
  name: "Project",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
    "table-action": resolve =>
      require(["@/components/table/TableAction.vue"], resolve),
    "table-delete": resolve =>
      require(["@/components/table/TableDelete.vue"], resolve),
    "project-statistic": resolve =>
      require(["@/components/model/ProjectStatistic.vue"], resolve)
  },
  data() {
    return {
      data: [],
      project_status: 3,
      columns,
      total: 10,
      size: 10,
      current: 1,
      search: undefined,
      loading: true
    };
  },
  created() {
    this.getProjectlist();
  },
  methods: {
    async getProjectlist() {
      const that = this;
      this.loading = true;
      try {
        let res = await fetchProjectlist({
          page_size: this.size,
          page: this.current,
          project_title: this.search,
          project_status: this.project_status,
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.project_list.map((item, index) => {
          item.pay_type = item.pay_type == 1 ? "及时发放" : "审核发放";
          switch (item.project_status) {
            case 1:
              item.project_status_text = "未发布"
              break;
            case 2:
              item.project_status_text = "暂停"
              break;
            case 3:
              item.project_status_text = "正在调查"
              break;
            case 4:
              item.project_status_text = "已完成"
              break;
            case 5:
              item.project_status_text = "奖励完成的"
              break;
            default:
              break;
          }
          item.project_type_text = "未知";
          switch (item.project_type) {
            case 1:
              item.project_type_text = "Full Service"
              break;
            case 2:
              item.project_type_text = "Sample only"
              break;
            case 3:
              item.project_type_text = "End Client"
              break;
            case 4:
              item.project_type_text = "API"
              break;
            case 5:
              item.project_type_text = "非盈利调查"
              break;
            default:
              break;
          }
          item["key"] = item.project_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async comfirmDelete(projectId) {
      this.$message.success("删除成功");
      this.getProjectlist();
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getProjectlist();
    },
    onSearch(e) {
      this.search = e;
      this.current = 1;
      this.getProjectlist();
    },
    onSuccess(e) {
      this.$router.push({ name: "projectDetail" });
    },
    selectStatus(e) {
      this.project_status = e;
      if (e == 0) {
        this.project_status = undefined;  
      }
      this.current = 1;
      this.getProjectlist();
    },
    onEdit(projectId) {
      this.$router.push({ name: "projectDetail", query: { projectId: projectId }});
    },
    /// 发布
    onPush(id) {
      this.updateStatus(id, 3);
    },
    /// 暂停
    onPause(id) {
      this.updateStatus(id, 2);
    },
    /// 完结
    onFinish(id) {
      this.updateStatus(id, 4);
    },
    async updateStatus(id, status) {
      this.loading = true;
      try {
        await updateProjectStatus({
          project_id: id,
          project_status: status
        });
        if (status == 3) {
          this.$message.success("已发布");
        } else if (status == 2) {
          this.$message.success("已暂停");
        } else if (status == 4) {
          this.$message.success("已完结");
        }
        this.loading = false;
        this.getProjectlist();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    setUrl(id, type, name) {
      this.$router.push({ name: "projectUrl", query: { projectId: id, urlType: type, name: name}});
    }
  }
};
</script>
